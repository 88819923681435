import logo from './logo.svg';
import './App.css';
import NewComponent from './NewComponent';
import EventHandlerTest from './eventhandler/EventHandlerTest';
import CradList from './components/CradList';




function App() {

  return (
    <div className="App">
      <CradList />
    </div>
  );
}

export default App;
