import React from 'react'
import CustomButton from '../components/custombuttons';

function EventHandlerTest(){


  return (
    <div>
      <input></input>
      <input></input>
       <CustomButton  name = {'paly movie'} message = {'palying movie'}/>
       <CustomButton  name = {'upload image'} message = {'uploading image'}/>
    </div>
  );
}

export default EventHandlerTest
