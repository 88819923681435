import React from 'react'

const Card = ({name,phone,address}) => {
  return (
    <div className='CARD'>
        <h2>{name}</h2>
        <p>{phone}</p>
        <p>{address}</p>
    </div>
  )
}

export default Card
