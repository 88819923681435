import React from "react";
import Card from "./Card";
import { useState } from "react";

const CradList = ({ searchText }) => {
  const peoples = [
    {
      name: "Ahmad Zia",
      phone: "+93 77 123 4567",
      address: "Karte Parwan, Kabul, Afghanistan",
    },
    {
      name: "Aisha Rahimi",
      phone: "+93 70 987 6543",
      address: "Shahr-e-Naw, Kabul, Afghanistan",
    },
    {
      name: "Rahmatullah Safi",
      phone: "+93 79 112 2334",
      address: "Wazir Akbar Khan, Kabul, Afghanistan",
    },
    {
      name: "Mariam Karimi",
      phone: "+93 78 876 5432",
      address: "Karte Seh, Kabul, Afghanistan",
    },
    {
      name: "Hamedullah Jalali",
      phone: "+93 72 345 6789",
      address: "Karte Char, Kabul, Afghanistan",
    },
    {
      name: "Farzana Akbari",
      phone: "+93 76 234 5678",
      address: "Taimani, Kabul, Afghanistan",
    },
    {
      name: "Sayed Mustafa",
      phone: "+93 75 543 2109",
      address: "Khair Khana, Kabul, Afghanistan",
    },
    {
      name: "Nargis Waziri",
      phone: "+93 73 678 9012",
      address: "Macroyan, Kabul, Afghanistan",
    },
    {
      name: "Zabiullah Hashimi",
      phone: "+93 77 890 1234",
      address: "Deh Mazang, Kabul, Afghanistan",
    },
    {
      name: "Layla Haidari",
      phone: "+93 71 456 7890",
      address: "Qala-e-Fathullah, Kabul, Afghanistan",
    },
  ];
  const [filter, setFilter] = useState("");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredPeople = peoples.filter(
    (person) =>
      (person.name.toLowerCase().includes(filter.toLowerCase()) ||
      person.phone.toLowerCase().includes(filter.toLowerCase()))
  );

  return (
    <>
      <input
        className="search-field"
        type="text"
        placeholder="Filter by name"
        value={filter}
        onChange={handleFilterChange}
      />
      <div
        id="mainContent"
        className="container"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          gridGap: "10px",
          gridAutoRows: "minMax(100px, auto)",
        }}
      >
        {filteredPeople.map((peaple) => (
          <Card
            key={peaple.phone}
            name={peaple.name}
            phone={peaple.phone}
            address={peaple.address}
          />
        ))}
      </div>
    </>
  );
};

export default CradList;
